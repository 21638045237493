import React from 'react';
import SettingItem from "./SettingItem";
import {TextField} from "@mui/material";

const TextSetting = ({
                       title,
                       settings,
                       updateSettings,
                       settingName,
                     }) => {
  return (
    <SettingItem title={title}>
      <TextField
        size={"small"}
        value={settings[settingName]}
        onChange={(e) => updateSettings(settingName, e.target.value)}
        inputProps={{maxLength: 20}}
      />
    </SettingItem>
  );
};


export default TextSetting;
