import React, {useRef} from 'react';
import {
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import {Add, Delete} from '@mui/icons-material';
import {styled} from '@mui/system';
// import HelpButton from './HelpButtton'
import {useTheme} from "@emotion/react";


const SmallTextField = ({size = 'small', ...props}) => {
  return <TextField
    size={size} {...props} />;
};

const TableTextField = styled(SmallTextField)(`
div {
border-radius: 0px
}
div:hover {
box-sizing: border-box;
border: 1px solid rgba(0, 0, 0, 1);
}

fieldset {
border: 0
}
`)

// Help

const TextTableCell = styled(TableCell)(`
padding: 0px 0px;
border-right: 1px solid rgba(224, 224, 224, 1);
`)

const OtherTableCell = styled(TableCell)(`
padding-top: 0px;
padding-bottom: 0px;
`)


const DataTable = ({data, onUpdateData}) => {

  const theme = useTheme()

  const tableRef = useRef(null);


  const handleAddRow = () => {
    // Default row values
    const newData = [
      ...data,
      {id: Date.now(), sourceName: '', targetName: '', value: 100, color: '#636363'},
    ];
    onUpdateData(newData);

    setTimeout(() => {
      // Move to the bottom
      if (tableRef.current) {
        tableRef.current.scrollTo({
          top: tableRef.current.scrollHeight,
          behavior: 'smooth'
        })
        // console.log('scrolled!')
      }
    }, 50)
  };

  const handleRemoveRow = (id) => {

    const newData = data.filter((row) => row.id !== id);
    onUpdateData(newData);

  };

  const handleInputChange = (id, field, value) => {
    const newData = data.map((row) =>
      row.id === id ? {...row, [field]: value} : row
    );
    onUpdateData(newData);
  };

  return (
    <Stack spacing={theme.spacing(2)}>

      <Paper sx={{width: '100%', overflow: 'hidden'}}>


        <TableContainer
          ref={tableRef}
          style={{height: '580px'}}
        >
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Row</TableCell>
                <TableCell>Source</TableCell>
                <TableCell>Target</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((row, i) => (
                <TableRow key={row.id}>
                  <TextTableCell sx={{padding: "8.5px 14px"}}>
                    {i + 1}
                  </TextTableCell>
                  <TextTableCell>
                    <TableTextField
                      style={{width: "100%"}}
                      type="text"
                      value={row.sourceName}
                      onChange={(e) =>
                        handleInputChange(row.id, 'sourceName', e.target.value)
                      }
                    />
                  </TextTableCell>
                  <TextTableCell>
                    <TableTextField
                      style={{width: "100%"}}
                      type="text"
                      value={row.targetName}
                      onChange={(e) =>
                        handleInputChange(row.id, 'targetName', e.target.value)
                      }
                    />
                  </TextTableCell>
                  <TextTableCell>
                    <TableTextField
                      style={{width: "100%"}}
                      type="number"
                      value={row.value}
                      onChange={(e) =>
                        handleInputChange(row.id, 'value', e.target.value)
                      }
                    />
                  </TextTableCell>
                  <OtherTableCell>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleRemoveRow(row.id)}
                      color="primary"
                    >
                      <Delete/>
                    </IconButton>
                  </OtherTableCell>
                </TableRow>
              ))}

            </TableBody>


          </Table>
        </TableContainer>

        <Button color="primary" onClick={handleAddRow}
                startIcon={<Add/>}
                style={{marginLeft: "20px"}}

        >
          Add Row
        </Button>
      </Paper>
    </Stack>
  );
};

export default DataTable;
