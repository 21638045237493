import SettingItem from "./SettingItem";
import {MenuItem, Select} from "@mui/material";
import React from "react";


const ChoiceSetting = ({
                         title,
                         settings,
                         updateSettings,
                         settingName,
                         choices
                       }) => {
  return (
    <SettingItem title={title}>
      <Select
        size={"small"}
        value={settings[settingName]}
        onChange={(e) => updateSettings(settingName, e.target.value)}
      >
        {choices.map((c) => (<MenuItem key={c.value} value={c.value}>{c.label}</MenuItem>))}
      </Select>
    </SettingItem>
  )

}

export default ChoiceSetting
