// Layout.js
import React from 'react';
import {Box} from "@mui/material";
import AppBar from './components/AppBar';
import Footer from "./components/Footer";
import {useTheme} from "@emotion/react";

const Layout = ({children, direction = 'column', noPadding}) => {

  const theme = useTheme()
  const contentContainerStyle = {
    display: "flex",
    flexDirection: direction,
    padding: noPadding ? 0 : theme.spacing(4),
    flex: 1
  }

  return (
    <Box sx={{display: "flex", flexDirection: "column", minHeight: "100vh"}}>
      <AppBar/>
      <Box sx={contentContainerStyle}>
        {children}
      </Box>
      <Footer/>
    </Box>
  );
};

export default Layout;
