import * as d3 from "d3";

/**
 * Return nodes colors based on graph data & settings
 * @param settings
 * @param nodes
 * @returns {*}
 */
function resolveNodesColor(settings, nodes) {
  if (settings.nodeColorOption === 'single') {
    return nodes.map((n) => settings.nodeSingleColor)
  } else if (settings.nodeColorOption === 'theme') {
    const colors = d3[`scheme${settings.nodeThemeColor}`];
    return nodes.map((n, i) => colors[i % colors.length])
  }
  // individual
  return nodes.map((n) => n.color)

}

/**
 * Return links colors based on graph data & settings
 * @param settings
 * @param links
 * @param nodes
 * @returns {*}
 */
function resolveLinksColor(settings, links, nodes) {


  if (settings.linkColorOption === 'single') {
    return links.map((l) => settings.linkSingleColor)
  } else if (settings.linkColorOption === 'source') {
    const nodesColor = resolveNodesColor(settings, nodes)
    const nodeColorMap = new Map()
    nodesColor.forEach((c, i) => {
      nodeColorMap.set(nodes[i].name, c)
    })
    return links.map((l) => nodeColorMap.get(l.sourceName))

  } else if (settings.linkColorOption === 'target') {
    const nodesColor = resolveNodesColor(settings, nodes)
    const nodeColorMap = new Map()
    nodesColor.forEach((c, i) => {
      nodeColorMap.set(nodes[i].name, c)
    })
    return links.map((l) => nodeColorMap.get(l.targetName))
  }

  return links.map((l) => l.color)
}

export {resolveLinksColor, resolveNodesColor}
