import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {CssBaseline} from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: [
      '"General Sans"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '2.25rem',
      lineHeight: 1.2222,
      letterSpacing: '-0.2px',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.625rem',
      lineHeight: 1.5,
      letterSpacing: '0.1px',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.25rem',
      lineHeight: 1.5,
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.125rem',
      lineHeight: 1.3333,
      fontWeight: 600,
    },
    h5: {
      fontSize: '0.875rem',
      lineHeight: 1.57,
      fontWeight: 600,
    },

  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

const APP_DESCRIPTION = "SankeyCharts is a powerful data visualization tool that allows " +
  "you to create stunning Sankey diagrams with ease. " +
  "Visualize the flow and relationships of your data using an intuitive interface. " +
  "Whether you're analyzing financial statements, your personal finances, supply chains, or network traffic, " +
  "SankeyCharts provides an interactive and insightful way to understand and communicate your data. " +
  "With customizable color schemes and advanced features, you can create professional-looking Sankey diagrams in minutes. " +
  "Try SankeyCharts today and unlock the hidden patterns in your data."

const APP_TITLE = 'SankeyCharts.com - Build beautiful diagrams for your finances'

const KEYWORDS = [
  "SankeyCharts", "Data Visualization", "Sankey Diagrams",
  "Intuitive Interface", "Financial Analysis", "Financial Statements",
  "Personal Finance", "Supply Chain", "Network Traffic",
  "Interactive Data Tool",
  "Customizable Diagrams",
  "Advanced Data Features",
  "Professional Sankey Diagrams",
  "Data Patterns",
  "SankeyMATIC",
  "Sankey"
]


root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <HelmetProvider>
        <Helmet>
          <title>{APP_TITLE}</title>
          <meta name="description" content={APP_DESCRIPTION}/>
          <meta name="keywords" content={KEYWORDS.join(", ")}/>
          <meta name="author" content="Pierre Schutz"/>
          <meta property="og:title" content={APP_TITLE}/>
          <meta property="og:description" content={APP_DESCRIPTION}/>
          <meta property="og:image" content="https://sankeycharts.com/sankeycharts.png"/>
          <meta property="og:url" content="https://sankeycharts.com"/>
          <meta property="og:type" content="website"/>
        </Helmet>
        <App/>
      </HelmetProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
