import React from 'react';
import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import MyColorPicker from "../util/MyColorPicker";
import SliderSetting from "./item/SliderSetting";
import ChoiceSetting from "./item/ChoiceSetting";
import SettingItem from "./item/SettingItem";

const NodesSettings = ({nodes, handleNodesUpdate, settings, updateSettings}) => {

  const handleNodeChange = (nodeName, field, value) => {
    const newNodesSettings = nodes.map(
      (ns) => ns.name === nodeName ? {...ns, [field]: value} : ns)

    handleNodesUpdate(newNodesSettings)
  }

  return (
    <>

      <Typography variant="h3">Style</Typography>

      <SliderSetting
        title={'Spacing'}
        settings={settings}
        updateSettings={updateSettings}
        settingName={"nodePadding"}
        min={1}
        max={100}
      />

      <SliderSetting
        title={'Width'}
        settings={settings}
        updateSettings={updateSettings}
        settingName={"nodeWidth"}
        min={1}
        max={100}
      />

      <SliderSetting
        title={'Border'}
        settings={settings}
        updateSettings={updateSettings}
        settingName={"nodeBorder"}
        min={0}
        max={10}
      />

      <SliderSetting
        title={'Opacity'}
        settings={settings}
        updateSettings={updateSettings}
        settingName={"nodeOpacity"}
        min={0}
        max={100}
      />

      <ChoiceSetting
        title={"Alignment"}
        settings={settings}
        updateSettings={updateSettings}
        settingName={"alignment"}
        choices={[
          {value: 'Left', label: 'Left'},
          {value: 'Right', label: 'Right'},
          {value: 'Center', label: 'Center'},
          {value: 'Justify', label: 'Justify'},
        ]}

      />

      <Divider/>

      <Typography variant="h3">Color</Typography>

      <ChoiceSetting
        title={"Node color"}
        settings={settings}
        updateSettings={updateSettings}
        settingName={"nodeColorOption"}
        choices={[
          {value: 'single', label: "Single color"},
          {value: 'theme', label: "Theme"},
          {value: 'individual', label: "Individual"}
        ]}/>

      {settings.nodeColorOption === 'single' &&
        <SettingItem title={"Color"}>
          <MyColorPicker
            value={settings["nodeSingleColor"]}
            onColorChange={(c) => updateSettings("nodeSingleColor", c)}/>
        </SettingItem>
      }

      {settings.nodeColorOption === 'theme' &&

        <ChoiceSetting
          title={"Color theme"}
          settings={settings}
          updateSettings={updateSettings}
          settingName={"nodeThemeColor"}
          choices={
            ["Category10",
              "Accent",
              "Dark2",
              "Paired",
              "Pastel1",
              "Pastel2",
              "Set1",
              "Set2",
              "Set3",
              "Tableau10"
            ].map((c) => ({value: c, label: c}))
          }/>
      }

      {settings.nodeColorOption === 'individual' && <TableContainer component={Paper}
                                                                    style={{
                                                                      maxHeight: '620px',
                                                                      overflowY: 'auto',
                                                                    }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Node</TableCell>
              <TableCell>Color</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {nodes.map((node) => (
              <TableRow key={node.name}>
                <TableCell>{node.name}</TableCell>
                <TableCell>
                  <MyColorPicker
                    value={node.color}
                    onColorChange={(c) => handleNodeChange(node.name, 'color', c)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      }


    </>

  );
};

export default NodesSettings;
