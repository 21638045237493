import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Box, Button, ButtonGroup, Stack, Typography} from "@mui/material";
import D3Sankey from "../components/D3Sankey";
import SettingsTabs from "../components/settings/SettingsTabs";
import {computeNodes, filterValidLinks} from "../util/chart";
import Layout from "../Layout";
import {DEFAULT_SETTINGS, SAMPLE_DATA} from "../data/chart";
import Loading from "../components/Loading";
import {useTheme} from "@emotion/react";
import {handleImportData, handleExportData} from "../util/import";
import {handleDownloadPng, handleDownloadSvg} from "../util/downloadImage";


const DEFAULT_NODE_COLOR = '#126ae0'

const ChartEdit = () => {

  const theme = useTheme()


  // Chart data: links, edited in DataTable
  const [links, setLinks] = useState([])
  const handleDataUpdate = (newData) => {
    setLinks(newData);
  };

  // Chart nodes (Edited in NodesSettings)
  const [nodes, setNodes] = useState([]);
  const handleNodesUpdate = (newNodes) => {
    setNodes(newNodes)
  }

  // Chart settings
  const [settings, setSettings] = useState({})

  const updateSettings = (settingName, value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [settingName]: value
    }))
  }

  // Function to save state to local storage
  const saveToLocalStorage = useCallback(() => {

    // console.log('Saving to local storage')
    // console.log(links)
    // console.log(nodes)
    // console.log(settings)

    localStorage.setItem('chartLinks', JSON.stringify(links));
    localStorage.setItem('chartNodes', JSON.stringify(nodes));
    localStorage.setItem('chartSettings', JSON.stringify(settings));
  }, [links, nodes, settings]);


  // Load from local storage when component mounts
  useEffect(() => {
    // console.log("Loading from local storage")

    const savedLinks = JSON.parse(localStorage.getItem('chartLinks'));
    const savedNodes = JSON.parse(localStorage.getItem('chartNodes'));
    const savedSettings = JSON.parse(localStorage.getItem('chartSettings'));

    // Assume that all exists if links exists
    if (savedLinks && savedLinks.length > 0) {
      setLinks(savedLinks)
      setNodes(savedNodes)
      setSettings(savedSettings)
    } else {
      // console.log("Setting default data")
      // Set defaults
      setLinks(SAMPLE_DATA) // nodes will be updated using links data
      setSettings(DEFAULT_SETTINGS)
    }
  }, []);

  // Auto-saving whenever states change
  useEffect(() => {
    if (links && links.length > 0) {
      saveToLocalStorage();
    }
  }, [links, nodes, settings, saveToLocalStorage]);


  // Update nodes adding new ones from new link
  useEffect(() => {

    if (links.length > 0) {
      // Compute unique nodes from data
      const newNodesName = computeNodes(filterValidLinks(links));

      // For each of the new node, if it existed already, apply the old settings
      setNodes(oldNodes => {

        let oldNodeNameToNode = {};

        oldNodes.forEach(node => {
          oldNodeNameToNode[node.name] = node;
        });

        return newNodesName.map((newNode) => {
          if (newNode in oldNodeNameToNode) {
            return oldNodeNameToNode[newNode]
          } else {
            return {
              name: newNode,
              color: DEFAULT_NODE_COLOR,
            }
          }
        })

      })
    }

  }, [links]);

  const isLoading = links.length === 0 || nodes.length === 0 || Object.keys(settings).length === 0;


  // Svg error
  const [svgError, setSvgError] = useState(false)

  // Svg ref
  const svgRef = useRef(null);

  return (
    <Layout>
      {isLoading ? <Loading/> : (
        <Stack direction={{md: 'column', lg: 'row'}} spacing={2}
               style={{flex: 1, paddingTop: '20px', paddingBottom: '20px'}}>
          <Box flex={1}>

            <Stack spacing={theme.spacing(2)}>

              <Typography variant="h2">Sankey Diagram</Typography>

              <ButtonGroup variant="outlined" aria-label="download buttons">
                <Button onClick={() => handleDownloadSvg(svgRef)}
                        disabled={svgError}
                >Download Chart SVG</Button>
                <Button onClick={() => handleDownloadPng(svgRef)}
                        disabled={svgError}
                >Download Chart PNG</Button>
              </ButtonGroup>

              <D3Sankey linksData={links} nodesData={nodes} settings={settings}
                        svgError={svgError} setSvgError={setSvgError}
                        svgRef={svgRef}
              />

            </Stack>

          </Box>
          <Box flex={1}>

            <Stack spacing={theme.spacing(2)}>
              <Typography variant="h2">Settings</Typography>

              <ButtonGroup variant="outlined" aria-label="import export buttons">
                <Button onClick={() => handleExportData(links, nodes, settings)}>Export chart data</Button>
                <Button onClick={() => handleImportData(setLinks, setNodes, setSettings)}>Import chart data</Button>
              </ButtonGroup>

              <SettingsTabs data={links}
                            handleDataUpdate={handleDataUpdate}
                            nodes={nodes}
                            handleNodesUpdate={handleNodesUpdate}
                            settings={settings}
                            updateSettings={updateSettings}/>

            </Stack>

          </Box>
        </Stack>
      )}

    </Layout>
  );
};

export default ChartEdit;
