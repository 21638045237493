import React from 'react';
import {AppBar as MuiAppBar, Stack, Toolbar, Typography} from '@mui/material';
import {useTheme} from "@emotion/react";
import {Link as RouterLink} from "react-router-dom";

const AppBar = () => {

  const theme = useTheme()
  return (
    <MuiAppBar position="static">
      <Toolbar>

        <Stack sx={{
          alignItems: 'center',
          color: 'white',
          textDecoration: 'none',
          '&:hover': {textDecoration: 'underline'},
          'cursor': "pointer"
        }}
               direction="row"
               spacing={theme.spacing(2)}
               component={RouterLink}
               to="/"
        >

          <img
            src="/logo.svg"
            alt="SankeyCharts Logo"
            style={{filter: 'invert(99%) sepia(1%) saturate(591%) hue-rotate(154deg) brightness(116%) contrast(100%)'}}
            width="30"
            height="30"
          />

          <Typography variant="h6">SankeyCharts.com</Typography>

        </Stack>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
