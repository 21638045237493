const DEFAULT_LINKS_COLOR = "#656565"

const SAMPLE_DATA = [
  {id: 1, sourceName: 'Wages', targetName: 'Income', value: 1100, color: DEFAULT_LINKS_COLOR},
  {id: 2, sourceName: 'Side hustle', targetName: 'Income', value: 400, color: DEFAULT_LINKS_COLOR},
  {id: 3, sourceName: 'Income', targetName: 'Taxes', value: 300, color: DEFAULT_LINKS_COLOR},
  {id: 4, sourceName: 'Income', targetName: 'Spending', value: 900, color: DEFAULT_LINKS_COLOR},
  {id: 5, sourceName: 'Income', targetName: 'Savings', value: 150, color: DEFAULT_LINKS_COLOR},
  {id: 6, sourceName: 'Income', targetName: 'Investment', value: 150, color: DEFAULT_LINKS_COLOR},
  {id: 7, sourceName: 'Spending', targetName: 'Rent', value: 500, color: DEFAULT_LINKS_COLOR},
  {id: 8, sourceName: 'Spending', targetName: 'Food', value: 150, color: DEFAULT_LINKS_COLOR},
  {id: 9, sourceName: 'Spending', targetName: 'Transportation', value: 50, color: DEFAULT_LINKS_COLOR},
  {id: 10, sourceName: 'Spending', targetName: 'Entertainment', value: 50, color: DEFAULT_LINKS_COLOR},
  {id: 11, sourceName: 'Spending', targetName: 'Shopping', value: 100, color: DEFAULT_LINKS_COLOR},
  {id: 12, sourceName: 'Spending', targetName: 'Health', value: 50, color: DEFAULT_LINKS_COLOR},
];

const DEFAULT_SETTINGS = {
  // Node settings
  nodePadding: 10,
  nodeWidth: 15,
  nodeBorder: 1,
  nodeOpacity: 80,
  nodeColorOption: 'theme',
  nodeSingleColor: '#126ae0',
  nodeThemeColor: 'Category10',
  alignment: "Center",
  // Link settings
  linkOpacity: 20,
  linkColorOption: 'target',
  linkSingleColor: '#656565',

  // Labels settings
  textSize: 14,
  textColor: '#000000',
  textPosition: "auto",
  textFontWeight: 400,
  textFontFamily: "Roboto, sans-serif",
  valuePrefix: "$",
  valueSuffix: "",
  valueFormat: ",."

}

export {SAMPLE_DATA, DEFAULT_SETTINGS}
