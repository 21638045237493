import React from 'react';
import SettingItem from "./SettingItem";
import MySlider from "../../util/MySlider";

const SliderSetting = ({
                         title,
                         settings,
                         updateSettings,
                         settingName,
                         min,
                         max,
                         step
                       }) => {
  return (
    <SettingItem title={title}>
      <MySlider value={settings[settingName]}
                valueLabelDisplay="auto"
                min={min}
                max={max}
                step={step}
                onChange={(e) => updateSettings(settingName, e.target.value)}
      />
    </SettingItem>
  );
};

SliderSetting.defaultProps = {
  min: 0,
  max: 100,
  step: 1
}

export default SliderSetting;


