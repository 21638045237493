import {generateId} from "./strings";

const handleImportData = (setLinksData, setNodesData, setSettings) => {
  const confirm = window.confirm("You are about to overwrite current chart data. Continue?")

  if (confirm) {

    const input = document.createElement("input");
    input.type = "file";
    input.accept = "application/json";

    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const contents = e.target.result;


          try {
            // TODO: Add data validation.
            const data = JSON.parse(contents);

            if (validateImportData(data)) {
              setLinksData(data.links_data)
              setNodesData(data.nodes_data)
              setSettings(data.settings)
            } else {
              console.error("Data validation failed")
              window.alert("Imported file data is not valid.")
            }

          } catch (error) {
            console.error("Error parsing JSON:", error);
            window.alert("An error occurred while parsing the file.");
          }
        };
        reader.readAsText(file);
      }
    };

    input.click();
  }
};

const handleExportData = (linksData, nodesData, settings) => {
  const chartData = {
    'links_data': linksData,
    'nodes_data': nodesData,
    'settings': settings
  }

  const jsonString = JSON.stringify(chartData, null, 2);
  const blob = new Blob([jsonString], { type: "application/json" });
  const href = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  const chartId = generateId().toString()
  link.download = `sankeycharts_${chartId}.json`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}


function validateImportData(data) {
  // Check if all required top-level fields exist
  if (!data.links_data || !data.nodes_data || !data.settings) {
    return false;
  }

  // Validate links_data
  if (!Array.isArray(data.links_data) || data.links_data.some(link => {
    return typeof link.id !== 'number' ||
      typeof link.sourceName !== 'string' ||
      typeof link.targetName !== 'string' ||
      typeof link.value !== 'number' ||
      typeof link.color !== 'string';
  })) {
    return false;
  }

  // Validate nodes_data
  if (!Array.isArray(data.nodes_data) || data.nodes_data.some(node => {
    return typeof node.name !== 'string' || typeof node.color !== 'string';
  })) {
    return false;
  }

  // Validate settings
  const settings = data.settings;
  if (typeof settings !== 'object' ||
    typeof settings.nodePadding !== 'number' ||
    typeof settings.nodeWidth !== 'number' ||
    typeof settings.nodeBorder !== 'number' ||
    typeof settings.nodeOpacity !== 'number' ||
    typeof settings.nodeColorOption !== 'string' ||
    typeof settings.nodeSingleColor !== 'string' ||
    typeof settings.nodeThemeColor !== 'string' ||
    typeof settings.alignment !== 'string' ||
    typeof settings.linkOpacity !== 'number' ||
    typeof settings.linkColorOption !== 'string' ||
    typeof settings.linkSingleColor !== 'string' ||
    typeof settings.textSize !== 'number' ||
    typeof settings.textColor !== 'string' ||
    typeof settings.textPosition !== 'string' ||
    typeof settings.textFontWeight !== 'number' ||
    typeof settings.textFontFamily !== 'string' ||
    typeof settings.valuePrefix !== 'string' ||
    typeof settings.valueSuffix !== 'string' ||
    typeof settings.valueFormat !== 'string') {
    return false;
  }

  return true;
}


export {handleImportData, handleExportData}
