import React from 'react';
import {Divider, Typography,} from '@mui/material';

import MyColorPicker from "../util/MyColorPicker";
import SliderSetting from "./item/SliderSetting";
import SettingItem from "./item/SettingItem";
import TextSetting from "./item/TextSetting";
import ChoiceSetting from "./item/ChoiceSetting";

const LabelsSettings = ({settings, updateSettings}) => {


  return (
    <>

      <Typography variant="h3">Text</Typography>

      <SliderSetting
        title={'Text size'}
        settings={settings}
        updateSettings={updateSettings}
        settingName={"textSize"}
        min={5}
        max={30}
      />

      <SettingItem title={"Text color"}>
        <MyColorPicker
          value={settings.textColor}
          onColorChange={(c) => updateSettings('textColor', c)}
        />
      </SettingItem>


      {/*<ChoiceSetting*/}
      {/*    title={"Position"}*/}
      {/*    settings={settings}*/}
      {/*    updateSettings={updateSettings}*/}
      {/*    settingName={"textPosition"}*/}
      {/*    choices={[*/}
      {/*        {value: "auto", label: 'Auto'},*/}
      {/*        {value: "before", label: 'Before'},*/}
      {/*        {value: "after", label: 'After'},*/}

      {/*    ]}*/}
      {/*/>*/}

      <SliderSetting
        title={"Font weight"}
        settings={settings}
        updateSettings={updateSettings}
        settingName={"textFontWeight"}
        step={100}
        min={100}
        max={900}
      />

      <ChoiceSetting
        title={"Font family"}
        settings={settings}
        updateSettings={updateSettings}
        settingName={"textFontFamily"}
        choices={[
          {value: "Roboto, sans-serif", label: 'Roboto'},
          {value: "'Courier New', monospace", label: 'Courier New'},
          {value: "'Times New Roman', serif", label: 'Times New Roman'},
          {value: "Arial, sans-serif", label: 'Arial'},


        ]}
      />

      <Divider/>

      <Typography variant="h3">Value</Typography>


      <TextSetting
        title={"Prefix"}
        settings={settings}
        updateSettings={updateSettings}
        settingName={"valuePrefix"}
      />

      <TextSetting
        title={"Suffix"}
        settings={settings}
        updateSettings={updateSettings}
        settingName={"valueSuffix"}
      />

      <ChoiceSetting
        title={"Format"}
        settings={settings}
        updateSettings={updateSettings}
        settingName={"valueFormat"}
        choices={[
          {value: ',.', label: '1,000.0'},
          {value: '.,', label: '1.000,0'},
          {value: ' .', label: '1 000.0'},
          {value: ' ,', label: '1 000,0'},
          {value: '.', label: '1000.0'},
          {value: ',', label: '1000,0'},

        ]}
      />

    </>
  );
};

export default LabelsSettings;
