import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore';
import {getAnalytics} from "firebase/analytics";

// Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyCZDkYb1cHj-dd2YNlwVvoZHedRNuo4YOg",
  authDomain: "sankeychartapp.firebaseapp.com",
  projectId: "sankeychartapp",
  storageBucket: "sankeychartapp.appspot.com",
  messagingSenderId: "224439761699",
  appId: "1:224439761699:web:a14535b91812bcf027aa96",
  mesurementId: "G-92S2M5C01G"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize analytics
const analytics = getAnalytics(app);

// Get Firestore instance
const db = getFirestore(app);

// console.log("Firebase initialized!")

export {app, analytics, db};
