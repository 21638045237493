const PNG_EXTRA_PADDING = 50
const DEFAULT_FILE_NAME = 'my_sankey_diagram' // TODO: Make it match with the export id


function svgUrlToPng(svgUrl, callback) {
  const svgImage = document.createElement('img');
  const scale = 2; // Scale factor
  // Do not show image to user.
  svgImage.style.visibility = 'hidden'

  document.body.appendChild(svgImage);
  svgImage.onload = function () {
    const canvas = document.createElement('canvas');
    canvas.width = svgImage.clientWidth * scale;
    canvas.height = svgImage.clientHeight * scale;

    // console.log(canvas.width, canvas.height)
    const canvasCtx = canvas.getContext('2d');
    // Scale the context
    canvasCtx.scale(scale, scale);
    // Add background
    // canvasCtx.fillStyle = "white";
    // canvasCtx.fillRect(0, 0, canvas.width, canvas.height);
    canvasCtx.drawImage(svgImage, PNG_EXTRA_PADDING / 2, PNG_EXTRA_PADDING / 2,);
    const imgData = canvas.toDataURL('image/png');
    callback(imgData);

    // Once download is complete, clear body from image element
    document.body.removeChild(svgImage);
  };
  svgImage.src = svgUrl;
}

const getSvgBlob = (svgRef) => {

  // Create a copy of the element
  const svgString = new XMLSerializer().serializeToString(svgRef.current);
  const svg = new DOMParser().parseFromString(svgString, 'text/xml').documentElement;

  svg.setAttribute('xmlns', "http://www.w3.org/2000/svg")
  // console.log(svgRef.current.getBoundingClientRect())
  const {width, height} = svgRef.current.getBoundingClientRect();
  svg.setAttribute('width', width + PNG_EXTRA_PADDING)
  svg.setAttribute('height', height + PNG_EXTRA_PADDING)

  return new Blob([svg.outerHTML], {type: "image/svg+xml;base64"})
}


const handleDownloadPng = (svgRef) => {
  const url = URL.createObjectURL(getSvgBlob(svgRef));

  const callback = (imgData) => {
    const link = document.createElement("a");
    link.href = imgData
    link.download = DEFAULT_FILE_NAME + '.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => URL.revokeObjectURL(url), 5000);
  }

  svgUrlToPng(url, (imgData) => {
    callback(imgData);
  });
}

const handleDownloadSvg = (svgRef) => {
  const objectUrl = URL.createObjectURL(getSvgBlob(svgRef));

  const link = document.createElement("a");
  link.href = objectUrl;
  link.download = DEFAULT_FILE_NAME + '.svg';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
};

export {handleDownloadSvg, handleDownloadPng}
