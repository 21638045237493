import React, {useEffect, useState} from 'react';
import * as d3 from 'd3';
import {sankey, sankeyCenter, sankeyJustify, sankeyLeft, sankeyLinkHorizontal, sankeyRight} from 'd3-sankey';
import {Alert, Box, Paper, Snackbar, Typography} from "@mui/material";
import {computeNodes, filterValidLinks, isSubSet} from "../util/chart";
import {formatNumber, renderLineBreaks} from "../util/strings";
import {resolveLinksColor, resolveNodesColor} from '../util/settings'
import {useTheme} from "@emotion/react";


const ALIGNMENT_MAP = {
  'Center': sankeyCenter,
  'Left': sankeyLeft,
  'Right': sankeyRight,
  'Justify': sankeyJustify
}

const SankeyError = () => {
  const theme = useTheme()
  return (
    <Typography
      color="error"
      padding={theme.spacing(4)}
    >Error: Unable to render the Sankey diagram.</Typography>
  )

}


const D3Sankey = ({linksData, nodesData, settings, svgError, setSvgError, svgRef}) => {
  const [dimensions, setDimensions] = useState({width: 500, height: 500});
  const [loading, setLoading] = useState(true)

  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'error'
  })

  const setAlertMessage = (message, type = 'error') => {
    setAlert({open: true, message: message, type: type})
  }

  // Function to close the error alert
  const handleCloseAlert = () => {
    setAlert({...alert, open: false})
  };


  // Handle dynamic dimensions
  useEffect(() => {

    const updateDimensions = () => {
      if (svgRef.current) { // Handle case where an error was thrown
        const {width, height} = svgRef.current.getBoundingClientRect();
        setDimensions({width, height});
        setLoading(false)
      }
    };

    updateDimensions(); // Initial dimensions

    // Event listener for window resize
    window.addEventListener('resize', updateDimensions);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [svgRef]);


  // Render chart
  useEffect(() => {

    try {
      // throw new Error("Example error")


      // 0. Format & validate data
      const validLinks = filterValidLinks(linksData, setAlertMessage)

      // const updatedLinks = validLinks.map(l => {source = l.sour)
      validLinks.forEach(l => {
        l.source = l.sourceName
        l.target = l.targetName
      })

      const validNodesSet = new Set(computeNodes(validLinks))

      // console.log(linksData)

      // If nodes in links are not yet been added to node, do not render graph
      if (!isSubSet(validNodesSet, new Set(nodesData.map(n => n.name)))) {
        return;
      }

      // Filter nodes that have been removed from links but still have configuration.
      // Deep copy of notes
      const nodesCopy = JSON.parse(JSON.stringify(nodesData))
      const validNodes = nodesCopy.filter((node) => validNodesSet.has(node.name))

      const d3Data = {
        // links: validLinks.map((d) => ({
        //     source: d.source,
        //     target: d.target,
        //     value: d.amount,
        //     color: d.color,
        // })),
        links: validLinks,
        nodes: validNodes
      }

      // Validate and filter data
      if (d3Data.links.length === 0) {
        return; // Exit early if data is empty
      }

      if (d3Data.nodes.length === 0) {
        return; // Nodes data not yet loaded
      }

      // Resolve node and link colors
      const nodesColor = resolveNodesColor(settings, d3Data.nodes);
      const linksColor = resolveLinksColor(settings, d3Data.links, d3Data.nodes);

      d3Data.nodes.forEach((node, i) => {
        node.color = nodesColor[i];
      });

      d3Data.links.forEach((link, i) => {
        link.color = linksColor[i];
      });

      // 1. Setup svg
      const svg = d3.select(svgRef.current);

      svg.selectAll("*").remove(); // Clear the SVG by removing its contents

      const {width, height} = dimensions;

      const paddingY = 20
      const paddingX = 20

      // 2. Set up the sankey layout
      const sankeyLayout = sankey()
        .nodeId(d => d.name) // Needed to avoid "Error: Missing: myNode"
        .nodeAlign(ALIGNMENT_MAP[settings.alignment])
        .nodeWidth(settings.nodeWidth)
        .nodePadding(settings.nodePadding)
        .extent([[paddingX, paddingY],
          [width - paddingX, height - paddingY]]);

      // 3. Add data to chart
      const {nodes, links} = sankeyLayout(d3Data);

      // console.log(nodes)
      // console.log(links)

      // 4. Draw chart

      // Set up the link generator
      const linkGenerator = sankeyLinkHorizontal();

      // Draw the links
      svg
        .append('g')
        .selectAll('path')
        .data(links)
        .join('path')
        .attr('d', linkGenerator)
        .attr('fill', 'none')
        .attr('stroke', (d) => d.color)
        .attr('stroke-opacity', settings.linkOpacity / 100)
        .attr('stroke-width', (d) => Math.max(1, d.width));


      // Draw the nodes
      const node = svg
        .append('g')
        .selectAll('g')
        .data(nodes)
        .join('g');


      node.append('rect')
        .attr('x', (d) => d.x0)
        .attr('y', (d) => d.y0)
        .attr('height', (d) => Math.abs(d.y1 - d.y0)) // TODO: Bug fix
        .attr('width', (d) => d.x1 - d.x0)
        .attr('fill', (d) => d.color)
        .attr('fill-opacity', settings.nodeOpacity / 100)
        .attr('stroke', '#000')
        .attr('stroke-width', settings.nodeBorder)

      // Add node text group
      const nodeText = node.append('g')
        .attr('fill', settings.textColor)
        .attr('font-family', settings.textFontFamily)
        .attr('font-weight', settings.textFontWeight)
        .attr('font-size', settings.textSize.toString() + 'px')

      // .style('fill', "#000000")
      // .style('font-size', '12px');

      // Add label
      nodeText
        .append('text')
        .attr('x', (d) => {
          if (settings.textPosition === 'auto') {
            return (d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6)
          } else if (settings.textPosition === 'before') {
            return d.x0 - 6
          } else {
            return d.x1 + 6
          }
        })
        .attr('y', (d) => (d.y1 + d.y0) / 2)
        .attr('text-anchor', (d) => {
          if (settings.textPosition === 'auto') {
            return (d.x0 < width / 2 ? 'start' : 'end')
          } else if (settings.textPosition === 'before') {
            return 'end'
          } else {
            return 'start'
          }
        })
        .attr('dy', '0.35em')
        .text((d) => d.name)

      // Add node value
      nodeText
        .append('text')
        .attr('x', (d) => (d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6))
        .attr('y', (d) => (d.y1 + d.y0) / 2)
        .attr('text-anchor', (d) => (d.x0 < width / 2 ? 'start' : 'end'))
        .attr('dy', '1.35em')
        .text((d) => settings.valuePrefix + formatNumber(d.value, settings.valueFormat) + settings.valueSuffix)


    } catch (e) {
      console.error("Error rendering D3 chart:", e);
      setSvgError(true); // Set error state to true
    }


  }, [svgRef, setSvgError, linksData, nodesData, dimensions, settings]);

  const svgVisibility = loading ? 'hidden' : 'visible' // Hide svg while resizing

  return (

    <Paper elevation={3}>
      {svgError ? <SankeyError/> :
        (
          <Box>
            <svg ref={svgRef} width="100%" height="800px" style={{visibility: svgVisibility}}></svg>
          </Box>
        )
      }
      <Snackbar
        open={alert.open}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert}
               severity={alert.type}
               sx={{width: '100%'}}
               variant={"filled"}
        >
          {renderLineBreaks(alert.message)}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default D3Sankey;
