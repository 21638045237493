import React, {useState} from 'react';
import {addDoc, collection} from 'firebase/firestore';
import {db} from '../util/firebase'
import {Alert, Box, Button, Stack, TextField, Typography} from '@mui/material';
import Layout from "../Layout";
import {useTheme} from "@emotion/react";

const FIRESTORE_COLLECTION = 'contactForm';

const ContactForm = () => {

  const theme = useTheme()

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({email: '', name: '', message: ''});
  const [success, setSuccess] = useState(false);

  const validateForm = () => {
    let isValid = true;
    const newErrors = {email: '', name: '', message: ''};

    if (!email) {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email is invalid';
      isValid = false;
    }

    if (!name) {
      newErrors.name = 'Name is required';
      isValid = false;
    }

    if (!message) {
      newErrors.message = 'Message is required';
      isValid = false;
    } else if (message.length < 3 || message.length > 2000) {
      newErrors.message = 'Message must be between 3 and 2000 characters';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const collectionRef = collection(db, FIRESTORE_COLLECTION);
        // Save the message to Firestore
        await addDoc(collectionRef, {
          name,
          email,
          message,
        });

        // TODO: Send email notification (implement server-side logic)

        // Reset form fields
        setName('');
        setEmail('');
        setMessage('');
        setErrors({email: '', name: '', message: ''});
        setSuccess(true);
      } catch (error) {
        console.error('Error saving message:', error);
      }
    }
  };

  return (
    <Layout>
      <Box component="form" noValidate autoComplete="off" sx={{flex: 1}} onSubmit={handleSubmit}>
        <Stack
          direction="column"
          spacing={2}
          paddingX={{sm: 0, md: theme.spacing(10)}}
          maxWidth={'1200px'}
          marginX={"auto"}
        >
          <Typography variant={"h1"}>Contact</Typography>
          <Typography>
            We're always eager to assist you and hear your thoughts about our services.
            Whether you have questions, need support, wish to report a bug,
            or have suggestions for new features or enhancements, we're here for you.
            <br/>
            Your feedback is invaluable to us, and we look forward to connecting with you.
          </Typography>
          <TextField
            label="Your Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            label="Your Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            label="Your Message"
            multiline
            rows={4}
            variant="outlined"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            error={!!errors.message}
            helperText={errors.message}
          />
          <Button type="submit" variant="contained" color="primary">
            Send Message
          </Button>
          {success && (
            <Alert severity="success">
              Thank you for your message! We will get back to you shortly.
            </Alert>
          )}
        </Stack>
      </Box>
    </Layout>
  );
};

export default ContactForm;
