import React from 'react';
import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import SliderSetting from "./item/SliderSetting";
import ChoiceSetting from "./item/ChoiceSetting";
import MyColorPicker from "../util/MyColorPicker";
import SettingItem from "./item/SettingItem";


const FlowsSettings = ({data, handleDataChange, settings, updateSettings}) => {

  const handleInputChange = (id, field, value) => {
    const newData = data.map((row) =>
      row.id === id ? {...row, [field]: value} : row
    );
    handleDataChange(newData);
  };


  return (
    <>
      <Typography variant="h3">Style</Typography>

      <SliderSetting
        title={"Opacity"}
        settings={settings}
        updateSettings={updateSettings}
        settingName={"linkOpacity"}
      />

      <Divider/>
      <Typography variant="h3">Color</Typography>

      <ChoiceSetting
        title={"Flow color"}
        settings={settings}
        updateSettings={updateSettings}
        settingName={"linkColorOption"}
        choices={[
          {value: 'single', label: "Single color"},
          {value: 'source', label: "Source node"},
          {value: 'target', label: "Target node"},
          {value: 'individual', label: "Individual color"},

        ]}
      />

      {settings.linkColorOption === 'single' &&
        <SettingItem title={"Color"}>
          <MyColorPicker
            value={settings["linkSingleColor"]}
            onColorChange={(c) => updateSettings("linkSingleColor", c)}
          />
        </SettingItem>
      }


      {settings.linkColorOption === 'individual' &&
        <TableContainer component={Paper}
                        style={{
                          maxHeight: '620px',
                          overflowY: 'auto',
                        }}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Flow</TableCell>
                <TableCell>Color</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((link) => (
                <TableRow key={link.sourceName + " " + link.targetName}>
                  <TableCell>{link.sourceName + "->" + link.targetName}</TableCell>
                  <TableCell>
                    <MyColorPicker
                      value={link.color}
                      onColorChange={(c) => handleInputChange(link.id, 'color', c)}
                    />
                  </TableCell>
                </TableRow>
              ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      }


    </>
  );
};

export default FlowsSettings;
