import React from 'react';
import {Box, Button, Stack, Typography} from "@mui/material";
import {Link as RouterLink} from 'react-router-dom';
import {useTheme} from "@emotion/react";


const Footer = () => {

  const theme = useTheme()

  return (
    <footer style={{
      backgroundColor: "#f5f5f5",
      padding: theme.spacing(2),
      textAlign: "center"
    }}>
      <Stack>
        <Box>
          <Button component={RouterLink} to="/contact">Contact</Button>
          <Button component={RouterLink} to='/'>Edit Diagram</Button>

        </Box>
        <Typography>&copy; Pierre Schutz 2023</Typography>
      </Stack>
    </footer>
  );
};

export default Footer;
