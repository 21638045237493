import React from 'react';
import {Box, Stack, Typography} from '@mui/material';

const SettingItem = ({title, children}) => {
  return (
    <Stack direction="row" alignItems="center">
      <Typography sx={{flex: 1}}>{title}</Typography>
      <Box sx={{flex: 1}}>
        {children}
      </Box>
    </Stack>
  );
};

export default SettingItem;
