import React from 'react';
import ContactForm from './pages/ContactForm';
import ChartEdit from "./pages/ChartEdit";
import './util/firebase'
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

const App = () => {


  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={<ChartEdit/>}
          />
          <Route
            path='/contact'
            element={<ContactForm/>}
          />

        </Routes>
      </Router>
    </>
  );
};

export default App;
