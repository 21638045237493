import React from 'react';
import {Box, CircularProgress} from '@mui/material';

const Loading = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flex={1}
      flexDirection="column"
    >
      <CircularProgress size={80} thickness={4}/>
      <Box mt={2}>Loading...</Box>
    </Box>
  );
};

export default Loading;
