import React, {useState} from 'react';
import {Box, Paper, Stack, Tab, Tabs} from '@mui/material';
import DataTable from './DataTable';
import NodesSettings from './NodesSettings';
import FlowsSettings from "./FlowsSettings";
import LabelsSettings from "./LabelsSettings";
import {styled} from "@mui/system";
import {useTheme} from "@emotion/react"; // Import the NodesSettings component


const MyTab = styled(Tab)`
  text-transform: none;
  color: black;
`

const SettingsTabs = ({data, handleDataUpdate, nodes, handleNodesUpdate, settings, updateSettings}) => {
  const theme = useTheme()
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  return (
      <Paper elevation={3}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs value={activeTab}
                onChange={handleTabChange}
                sx={{'& .MuiTab-root': {fontSize: '1.2rem'}}}
          >
            <MyTab label="Data"/>
            <MyTab label="Nodes"/>
            <MyTab label="Flows"/>
            <MyTab label="Labels"/>
          </Tabs>
        </Box>
        <Box padding={theme.spacing(2)}>
          <Stack spacing={theme.spacing(2)}>
            {activeTab === 0 && <DataTable data={data} onUpdateData={handleDataUpdate}/>}
            {activeTab === 1 && <NodesSettings
              nodes={nodes}
              handleNodesUpdate={handleNodesUpdate}
              settings={settings}
              updateSettings={updateSettings}
            />}
            {activeTab === 2 && <FlowsSettings data={data} handleDataChange={handleDataUpdate}
                                               settings={settings} updateSettings={updateSettings}/>}
            {activeTab === 3 && <LabelsSettings settings={settings} updateSettings={updateSettings}/>}
          </Stack>
        </Box>

      </Paper>
  );
};

export default SettingsTabs;
