import React from 'react';

function renderLineBreaks(str) {
  const lines = str.split('\n');
  return lines.map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br/>
    </React.Fragment>
  ))

}


function formatNumber(number, format) {
  const formattedNumber = number.toLocaleString('en', {
    useGrouping: true,
  });

  let thousandsDivider = ',';
  let decimalDivider = '.';

  // Replace thousands separators with ?
  // Replace decimal divider with $
  if (format.length === 2) {
    thousandsDivider = format[0];
    decimalDivider = format[1];
  } else if (format.length === 1) {
    thousandsDivider = '';
    decimalDivider = format[0];
  } else {
    return formattedNumber;
  }

  // Placeholder is used to avoid replacing the new thousandsDivider with a decimalDivider.
  let placeholderNumber = formattedNumber.replaceAll(',', '?').replaceAll('.', '$');
  return placeholderNumber.replaceAll('?', thousandsDivider).replaceAll('$', decimalDivider);
}

function generateId() {
  return Math.round(Math.random() * 1000000000) // Replace this with firebase push id.
}

export {renderLineBreaks, formatNumber, generateId};
