import React, {useRef, useState} from 'react'
import {SketchPicker} from 'react-color'


const MyColorInput = ({value, onColorChange}) => {
  const [displayPicker, setDisplayPicker] = useState(false);
  const inputRef = useRef()

  const handleClick = () => {
    setDisplayPicker(old => !old)
  };

  const handleClose = () => {
    setDisplayPicker(false)
  };

  const handleChange = (color) => {
    onColorChange(color.hex)
  };


  // Move position if near end of screen
  const inputPosition = inputRef.current ? inputRef.current.getBoundingClientRect() : null;
  const isNearBottom = inputPosition ? inputPosition.bottom > window.innerHeight - 400 : false;
  const isNearEnd = inputPosition ? inputPosition.right > window.innerWidth - 300 : false;

  const translateX = isNearEnd ? '-80%' : '0'
  const translateY = isNearBottom ? '-115%' : '0'

  return (
    <div>
      <div style={{
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      }}
           ref={inputRef}
           onClick={handleClick}>
        <div style={{
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: value,
        }}/>
      </div>
      {displayPicker ? <div style={{
        position: 'absolute',
        zIndex: '2',
      }}>
        <div style={{
          position: 'fixed',
          top: '0px',
          bottom: '0px',
          left: '0px',
          right: '0px'
        }} onClick={handleClose}/>
        <div style={{
          transform: `translate(${translateX}, ${translateY})`,
        }}>
          <SketchPicker
            color={value}
            onChange={handleChange}
          />
        </div>
      </div> : null}

    </div>
  )

}

export default MyColorInput

