/**
 * Compute the nodes object from the given data array.
 * The nodes object contains unique values from the source and target properties of the data.
 * @param {Array} links - The data array containing objects with source and target properties.
 * @returns {Object} The nodes object with unique values as keys and color values as values.
 */
function computeNodes(links) {

  const nodesSet = new Set();

  links.forEach(function (entry) {
    nodesSet.add(entry.sourceName);
    nodesSet.add(entry.targetName);
  });

  return Array.from(nodesSet);
}

/*
Return only rows that have valid data
 */
function filterValidLinks(links, setAlertMessage = null) {

  // Remove empty links / source == target

  const filtered_links = links.map((link) => {
    // Remove blanks
    const newLink = {...link}
    newLink.sourceName = newLink.sourceName.trim()
    newLink.targetName = newLink.targetName.trim()
    return newLink
  }).filter((link) => {
      // Remove empty
      // TODO: Might be able to remove source=target now that we handle circles.
      return link.sourceName !== '' && link.sourceName !== '' && link.sourceName !== link.targetName;
    }
  )

  const [valid_links, invalid_links] = incrementalCircularReferenceCheck(filtered_links)

  if (invalid_links.length > 0 && setAlertMessage) {
    // Add alert message with invalid links details

    let linkCountMsg = invalid_links.length > 1 ? 'links were' : 'link was'
    let errorMsg = `A Sankey diagram cannot have cycles. ${invalid_links.length} invalid ${linkCountMsg} ignored:`

    invalid_links.slice(0, 5).forEach(link => {
      errorMsg += `\n - ${link.sourceName} -> ${link.targetName} (${link.value})`
    })

    if (invalid_links.length > 5) {
      errorMsg += `\n ...`
    }

    setAlertMessage(errorMsg)
  }

  return valid_links
}

const isSubSet = (mySet, otherSet) => {
  if (mySet.size > otherSet.size)
    return false;
  for (let elem of mySet) {
    if (!otherSet.has(elem))
      return false;
  }
  return true;
}

function hasCircularReference(links) {


  const nodesMap = new Map();
  for (let link of links) {
    if (!nodesMap.has(link.sourceName)) {
      nodesMap.set(link.sourceName, []);
    }
    nodesMap.get(link.sourceName).push(link.targetName);
  }

  // console.log(nodesMap)

  const visit = (node, visited, stack) => {
    if (stack.has(node)) return true;
    if (visited.has(node)) return false;

    visited.add(node);
    stack.add(node);

    const neighbors = nodesMap.get(node) || [];
    for (let neighbor of neighbors) {
      if (visit(neighbor, visited, stack)) return true;
    }

    stack.delete(node);
    return false;
  };

  for (let node of nodesMap.keys()) {
    if (visit(node, new Set(), new Set())) return true;
  }
  return false;
}

const incrementalCircularReferenceCheck = (links) => {
  // TODO: This function is quite inefficient, it performs a DFS for all graphs in the tree.

  let has_circle = true
  // While the graph has a circle, remove last link, and check again.

  let newLinks = [...links]
  has_circle = hasCircularReference(newLinks)

  let removedLinks = []

  while (has_circle && newLinks.length >= 1) {
    const removedLink = newLinks.pop()
    removedLinks.push(removedLink)
    has_circle = hasCircularReference(newLinks)
  }

  return [newLinks, removedLinks]
}


export {computeNodes, filterValidLinks, isSubSet}
